.socialIcons {
    height: 40px;
    padding: 5px;
}

.socialIcons img {
    height: 30px;
    width: 30px;
    object-fit: cover;
    transition: 0.4s;
}

.socialIcons:hover img {
    height: 40px;
    width: 40px;
    object-fit: cover;
}