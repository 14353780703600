.closeAccordionBar {
    max-height: 900px;
    /* transition: max-height 0.5s linear; */
}

.openAccordionBar {
    max-height: 0px;
    overflow: hidden;
    /* transition: max-height 0.5s linear 0.5s; */
}

.openAccordion {
    max-height: 900px;
    transform: scaleY(max-height);
    transition: all 0.4s linear;
}

.closeAccordion {
    max-height: 0px;
    overflow: hidden;
    transform: scaleY(0);
    transition: all 0.0s linear;
}


.realTimeOuter {
    /* background-image: url("../../../Assets//backgrouondImg/Rectangle_156.png"); */
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: cover; */
    content: "";
    /* height: 500px; */
}

.realTimeInner {
    background-color: rgba(33, 32, 32, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.realBtn {
    /* background: linear-gradient(90deg, #ac892f, #f5f1a9, #ac892f); */
    background: inherit;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    /* text-align: center; */
}

.realRightBtn {
    width: 6rem;
    min-width: 50px;
    background-color: #116e51;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.realRightArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 100%;
}

.borderStyle {
    border: 1px solid #116e51;
}

@media only screen and (max-width: 700px) {
    .realRightArea {
        width: 7rem;
    }

    .verticalP {
        font-size: 10px !important;
    }
}