.box {
    --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/16.00px 100%;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.offer-card-video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 130px;
}

.offer-card-video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}