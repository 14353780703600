.process-card-font-size {
    font-size: 13px;
}

.regular-item {
    color: #000;
    background-color: var(--lightGray);
}

.selected-item {
    color: #000;
    background-color: var(--yellow);
    border: none;
}

.activity-item {
    cursor: pointer;
}