.loader-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    background-color: rgba(128, 128, 128, 0.100);
    /* opacity: 0.1; */
}

.spinner-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.500);
    border-radius: 10px;
    opacity: 1;
    z-index: 999;
    height: 100px;
    width: 100px;
}

@media only screen and (max-width: 992px) {
    .spinner-square {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}