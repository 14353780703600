.pcsoAboutSlider .types-card-text{
    font-size: 14px;
    /* color: #464646; */
  }
  .pcsoAboutSlider .bordered-custom-button{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 20px;
    background-color: #03A17B;
    border-radius: 4px;
    border: 2px solid #03A17B;
  }
  /* .bordered-custom-button:focus{
    color: #fff;
    background-color: #03A17B;
  } */
  
  .pcsoAboutSlider .swiper-button-prev, .pcsoAboutSlider .swiper-button-next {
    /* width: 0; 
    height: 0; 
    background-image: transparent;
    color: transparent;
     */
     display: none;
  }
  /* .swiper-button-prev {
  border-left: 2px solid transparent;
  border-right: 15px solid #03A17B;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  left: -1px;
  }
  
  .swiper-button-next {
  border-left: 15px solid #03A17B;
  border-right: 2px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  right: -1px;
  } */
   .pcsoAbout .button-previous, .pcsoAbout .button-next{
    font-size: 40px;
    color: #fff;
    cursor: pointer;
  }
  /* .button-previous:active, .button-next:active{
    color: #03A17B;
  }
  .button-previous:disabled, .button-next:disabled{
    color: #A6A6A6;
  } */
   .pcsoAbout .button-previous:disabled, .pcsoAbout .button-next:disabled {
    color: #A1A1A1;
    /* opacity: .35; */
    cursor: auto;
    pointer-events: none;
  }