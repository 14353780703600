.qr-deposit .imgModalContainer,
.transactionModal .imgModalContainer {
    background: linear-gradient(90deg, #ac892f, #EACB7D, #ac892f);
    border-radius: 0.2em;
    width: 244px;
    /* height: 244px; */
    height: 'auto';
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-deposit .modalImage,
.transactionModal .modalImage {
    background-image: linear-gradient(#D9D9D9, #D9D9D9);
    border-radius: 0.2em;
    width: 240px;
    /* height: 240px; */
    height: 'auto';
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.qr-deposit .modalImage img,
.transactionModal .modalImage img {
    width: 100%;
    height: auto;
    overflow: hidden;
    /* max-height: 100%; */
}

.qr-deposit .userImage {
    height: 300px;
    width: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-deposit .userImage label {
    height: 300px;
    width: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
}