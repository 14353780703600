.slidingNavbar .nav-link {
  width: auto;
  height: 60px;
  font-size: 0.8em;
  border-bottom: 2px solid transparent;
  background-image: linear-gradient(transparent, transparent);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  padding: 0.8em !important;
  /* margin: 0 0 5px 0 !important; */
  border-radius: 4px;
  cursor: pointer;
  /* transition: 0.8s; */
  filter: grayscale(100%) contrast(10%);
}

.slidingNavbar .nav-active {
  width: auto;
  height: 60px;
  font-size: 0.8em;
  border-bottom: 2px solid transparent;
  /* background-image: linear-gradient(transparent, transparent); */
  background-image: linear-gradient(#3d3d3d, #3d3d3d);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  padding: 0.8em !important;
  /* margin: 0 0 5px 0 !important; */
  border-radius: 4px;
  cursor: pointer;
  /* transition: 0.8s; */
  text-decoration: none;
}

.slidingNavbar .nav-link:hover{
  /* background-image: linear-gradient(transparent, transparent); */
  background-image: linear-gradient(#3d3d3d, #3d3d3d);
  filter: none;
}

.slidingNavbar .nav-link:hover p{
  color: var(--yellow);
}

.slidingNavbar:hover .nav-active{
  color: var(--yellow);
  background-image: linear-gradient(transparent, transparent);
}

.slidingNavbar .nav-active:hover{
  color: var(--yellow);
  background-image: linear-gradient(#3d3d3d, #3d3d3d);
}

.slidingNavbar img {
  height: 1.8em;
  width: auto;
}

.slidingNavbar .nav-link p,
.slidingNavbar .nav-active p{
  padding: 0;
  margin: 0.1em 0 0 0;
  color: var(--gray);
  font-family: Advent Pro;
  font-weight: bold;
}

.slidingNavbar .nav-active p,
.slidingNavbar .temp p {
  color: var(--yellow);
}

