.carousel-indicators {
    display: none !important;
}

.carousel-inner, .carousel-item{
    height: 22vh;
}


.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}