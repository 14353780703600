.home{
    height: 100dvh;
}

.trophyBtn {
    background-color: #ffffff;
    color: '#000000';
    height: 30px;
    width: 50px;
    box-shadow: 5px 0 .3em #3d3d3d;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    border-radius: 0 5px 5px 0;
    border: 0;
}

.home .home-card-area .home-card {
    background-image: url("../../Assets/Images/Home/home_card_background_img.png");
    background-color: var(--homeCardColo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.home .home-card-area .home-card h5 {
    font-family: Advent Pro;
    font-weight: bold;
    margin-left: 30px;
}

.home .home-card-area .home-card img {
    height: 100%;
    width: auto;
    max-width: 60%;
}

@keyframes load-effect {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}




/* ===| Install view Area |============================================================================================================================= */


.toast-body {
    /* transparent-yellow */
    background-color: #FFFFFF40;
}

.toast-body .btn-install {
    background-color: var(--yellow);
}


/* Hide it on large screens - You can change that */
.block__install {
    display: none;
}

/* We want to display it only on mobile */
@media (max-width: 768px) {
    .block__install {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 999999;
        background: white;
        padding: 15px;
        box-shadow: #FFFFFF44;
    }

    .is-active {
        display: block;
    }




    .block__install .inner {
        display: flex;
        align-items: center;
    }

    .block__install .inner .close {
        width: 32px;
        height: 32px;
        line-height: 32px;
    }

    .block__install .inner .logo {
        width: 48px;
    }

    .block__install .inner .name {
        padding-left: 10px;
    }

    .block__install .inner .cta {
        margin-left: auto;
    }



    .block__install .inner .logo img {
        width: 42px;
        border-radius: 2px;
    }

    .block__install .inner .close img {
        width: 32px;
        height: 32px;
    }

    .block__install .inner .name span {
        display: block;
    }

    .block__install .inner .name span.title {
        font-size: 1.125rem;
        line-height: 1;
        font-weight: 600;
    }

}