.menu .title-font {
    font-family: 'Ubuntu';
    font-weight: 700;
    font-size: 1.6em;
    text-transform: capitalize;
}

.menu .item-text {
    font-family: 'Advent Pro';
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.menu .item {
    background-color: #1F1F1F;
    color: var(--light);
    border-radius: 0.3em;
}

.menu .logout img {
    /* filter: hue-rotate(300deg); */
    filter: hue-rotate(105deg) invert(100%)
}

.available-balance-section {
    background-image: url("../../../Assets/Images/UserProfile/balance-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    color: var(--light);
}

.available-balance-section .bottom-underlined {
    border-bottom: 2px solid var(--light);
}