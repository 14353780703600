.registration h3 {
    /* color: black; */
    text-align: center;
    font-weight: bold;
    padding: 0.5em 0;
    margin: 0;
}

.registration .termsArea {
    font-size: 0.8em !important;
}

.countryIconStyle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}