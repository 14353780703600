  .pointer {
    position: relative;
    background: var(--dark);
    height: 50px;
    color: var(--yellow);
  }

  .pointer::after {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid var(--dark);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;

  }

  .prize-table-font {
    font-size: 14px;
  }
  .prize-info-font {
    font-size: 14px;
  }
  
  @media only screen and (max-width: 768px) {
    .prize-table-font {
      font-size: 11px;
    }
    .prize-info-font {
      font-size: 14px;
    }
  }