@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style-type: none;
  text-decoration: none;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Advent Pro', 'Roboto',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --light: #FFFFFF;
  --dark: #262626;
  --yellow: #FFC800;
  --transparent-yellow: #FFC80060;
  --homeCardColo: #DFE8FF;
  --lightGray: #EDEDED;
  --gray: #6C6C6C;
  --tomato: #E20000;
  --green: #409C1F;
}



/* ============ scrollbar start =========== */
::-webkit-scrollbar {
  height: 0px;
  /* width: 6px; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  -webkit-box-shadow: inset 20px 10px #dddddd;
}

/* ============ scrollbar end =========== */



/* ============ Filters =========== */
.saturate {
  filter: saturate(3);
}

.grayscale {
  filter: grayscale(120%);
}

.contrast {
  filter: contrast(160%);
}

.brightness {
  filter: brightness(0.25);
}

.blur {
  filter: blur(3px);
}

.invert {
  filter: invert(100%);
}

.sepia {
  filter: sepia(50%);
}

.huerotate {
  filter: hue-rotate(180deg);
}

.rss.opacity {
  filter: opacity(50%);
}

.multipleFilter {
  filter: grayscale(100%) contrast(10%);
}

/* ============ Filters End =========== */



input,
select {
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: inherit;
  transition: background-color 1000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff;
}

.green-text {
  color: var(--green);
}

.green-bg {
  background-color: var(--green);
  color: var(--light);
}

/* ============ white btn =========== */

.btn-white {
  color: #000000;
  background-color: var(--light);
  border-color: var(--gray);
  transition: 0.3s;
}

.btn-white:hover {
  color: #000000;
  background-color: var(--light);
  border-color: var(--gray);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.btn-white:active {
  color: #000000;
  background-color: var(--light);
  border-color: var(--gray);
}

.btn-white:disabled {
  color: #000000;
  background-color: var(--light);
  border-color: var(--gray);
}


/* ============ yellow btn =========== */
.btn-yellow {
  color: #000000;
  background-color: var(--yellow);
  border-color: var(--yellow);
  transition: 0.3s;
}

.btn-yellow:hover {
  color: #000000;
  background-color: var(--yellow);
  border-color: var(--yellow);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.btn-yellow:active {
  color: #000000;
  background-color: var(--yellow);
  border-color: var(--yellow);
}

.btn-yellow:disabled {
  color: #000000;
  background-color: var(--transparent-yellow);
  border-color: var(--transparent-yellow);

}

/* ============ red btn =========== */
.btn-green {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
  transition: 0.3s;
}

.btn-green:hover {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.btn-green:active {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

.btn-green:disabled {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

/* ============ green btn =========== */
.btn-green {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
  transition: 0.3s;
}

.btn-green:hover {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.btn-green:active {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

.btn-green:disabled {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

/* ============ dark button =========== */
.dark-button {
  color: var(--light);
  background-color: var(--dark);
  border-color: var(--dark);
  transition: 0.6s;
  white-space: nowrap;
}

.dark-button:hover {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
  transition: 0.6s;
}

.dark-button:active {
  color: var(--light);
  background-color: var(--yellow);
  border-color: var(--yellow);
  transition: 0.6s;
}

.dark-button:disabled {
  color: var(--light);
  background-color: var(--transparent-white);
  border-color: var(--transparent-white);
}

/* autofill styles */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #fff;
  transition: background-color 1000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  caret-color: #000;
}

/* hide scrollbar of Transaction Modals */

/* Hide scrollbar for Chrome, Safari and Opera */
.transactionModal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.transactionModal {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* users and admin tables modal */

.transactionModal .modal-content {
  /* max-width: 100%; */
  min-height: calc(100vh - 38px) !important;
  margin-top: 38px !important;
  border: none !important;
}

.transactionModal {
  min-width: 100%;
  /* padding: 0 !important; */
  --bs-modal-margin: 0 !important;
  --bs-modal-width: 100% !important;
  --bs-modal-border-radius: 10px 10px 0 0 !important;

  /* animation */
  animation-name: modalFadeFromBottomToUp !important;
  animation-duration: .8s;
}

.offerDetailsModal .modal-body {
  padding: 0 !important;
}

.transactionModal.offerDetailsModal.modal.show {
  padding-left: 0 !important;
  /* or any other value you want */
}

.gs-popup {
  --bs-modal-width: 100% !important;
}

@keyframes modalFadeFromBottomToUp {
  from {
    transform: translateY(+100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 992px) {

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

}

@media only screen and (min-width: 992px) {

  /* .transactionModal .modal-content {
  max-width: 33%;
  margin: 0 auto;
  } */

  .transactionModal {
    --bs-modal-margin: 0 !important;
    --bs-modal-width: 33% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .offerDetailsModal {
    --bs-modal-width: 33% !important;
    --bs-modal-width: calc(33% + 17px) !important;
  } */

  .gs-popup {
    --bs-modal-width: 33% !important;
  }
}