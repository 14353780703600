.video-frame {
    border-radius: 10px;
    background: linear-gradient(to left, #70E5FF, #C850C0, #4158D0);
    width: 100%;
    height: 325px;
    height: 'auto';
}

.video-frame iframe {
    width: 100%;
    height: 100%;
    padding: 8px;
}

.result-container {
    border-radius: 10px;
    background: linear-gradient(to left, #70E5FF, #C850C0, #4158D0);
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.result-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 8px;
}

@media only screen and (max-width: 992px) {
    .video-frame {
        height: 280px;
    }

    .video-frame iframe {
        padding: 8px;
    }
}