.bankingProfile {
    font-family: "Inter";
}

.bankingProfile .disable-green-btn,
.transactionHistoryTable .disable-green-btn {
    background: linear-gradient(180deg, #7daa2f, #7daa2f, #7daa2f);
    color: aliceblue !important;
    padding: 1px 5px !important;
    font-size: 15px !important;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    opacity: 0.5;
    cursor: auto;
}

.history-blurred-text {
    color: #000;
    opacity: 0.3;
}

@media only screen and (max-width: 992px) {
    .transactionHistoryTable thead {
        font-Size: 14px;
    }

    .transactionHistoryTable tbody {
        font-Size: 10px;
    }
}