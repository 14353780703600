.App {
  /* background-image: url("./Assets/Images/Home/home_background_img.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100dvh;
}

.start-bg {
  background-color: #2A303E;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.started-progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.main {
  background-color: #ffffff;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

/* .inner-scroll {
  animation: load-effect 300ms duration timing-function delay iteration-count direction fill-mode;
  animation: load-effect 300ms;
} */


@media (min-width: 992px) {
  .started-progress-bar {
    width: 33%;
  }

  .main {
    background-color: #ffffff;
    height: 100dvh;
    width: 33%;
  }
}