.alertModal .modal-dialog {
    position: relative;
    width: auto;
    /* max-width: 400px; */
    /* margin: 0 !important; */
    margin-top: 0 !important;
    pointer-events: none;
}

.alertModal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    max-width: 480px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}