/* pcsoArrowBar */
.pcsoArrowBar .whiteArea {
    position: relative;
    background: var(--lightGray);
    height: 44px;
    color: #000000;
    border-radius: 3px 0 0 3px;
    margin-right: 6px;
    z-index: 5;
}

.pcsoArrowBar .whiteArea::after {
    content: "";
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid var(--lightGray);
    border-top: 22.5px solid transparent;
    border-bottom: 22.5px solid transparent;

}

.pcsoArrowBar .goldenArea {
    position: relative;
    background: #ffffff;
    height: 44px;
    color: #000000;
    border-radius: 3px 0 0 3px;
}

.pcsoArrowBar .goldenArea::after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid var(--light);
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

}

.pcsoArrowBar .darkCircle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--dark);
    color: #FFFFFF;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;
}

.pcsoArrowBar .greenCircle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
    background-color: var(--green);
    color: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
}