.pcsoHome {
    /* background-color: #1F1F1F; */
    /* background-image: url(../../../../Assets/backgrouondImg/pcso-bet-bg.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.vr {
    content: "";
    width: 2px;
    height: 100;
    background-color: var(--gray);
    color: var(--gray);
    margin: 0 0 0 0.2rem;
}

.pcsoHome .form-select {
    background-color: transparent !important;
    border: 0 !important;
    color: #FFF !important;
    padding: 0 2.25rem 0 0.35rem !important;
    width: 100%;
    /* background-image: url(../../../../Assets/LotteryPage//PcsoTestimonial/down_solid_arraow.svg) !important; */
}

/* .pcsoHome select option:first-child {
    display: none;
  } */

.pcsoHome .form-select:focus-visible {
    background-color: transparent !important;
    border: 0 !important;
    color: #000 !important;
    box-shadow: none !important;
}

.pcsoHome :focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}


.pcso_select option:not(:checked) {
    color: #fff;
    background-color: #000000;
}

.pcso_select option:checked {
    color: #fff;
    background-color: rgba(235, 151, 47, 1);
}

.pcso_select option:focus,
.pcso_select option:hover {
    color: #fff;
    background-color: rgba(235, 151, 47, 1);
}

.betNowButton button {
    border: none;
    outline: none;
    background-image: linear-gradient(to right, rgba(235, 151, 47, 1), rgba(251, 217, 96, 0.92));
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
}

.betNowButton button:focus,
.betNowButton button:active {
    border: none;
    outline: none;
}

.pcsoHome table input {
    /* background-color: #000000; */
    border: none;
    height: 100%;
    width: 100%;
    margin: 0 2px;
    padding: 10px;
    color: #000;
    text-align: center;
    font-weight: bold;
}

.pcsoHome table input:focus,
.pcsoHome table input:active {
    /* background-color: #3D3D3D; */
    border: none;
    height: 100%;
    width: 100%;
    margin: 0 2px;
    padding: 10px;
    color: #000;
    text-align: center;
}


.pcsoHome table input:first-child {
    /* background-color: #000000; */
    border: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 10px;
    color: #000;
    text-align: center;
}

.pcsoHome table input:first-child:focus,
.pcsoHome table input:first-child:active {
    /* background-color: #3D3D3D; */
    border: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 10px;
    color: #000;
    text-align: center;
}

.pcsoHome table input:disabled {
    background-color: #3D3D3D;
}

.pcsoHome .tableGrayBtn {
    /* background-color: #3D3D3D; */
    color: #D4AF37;
    padding: 0 6px;
    font-size: 23px;
    border: none;
}