.left-bar {
    background-color: var(--lightGray);
}

.left-bar .sqrBtn {
    width: auto;
    height: auto;
    font-size: 0.5rem;
    /* border-bottom: 2px solid transparent; */
    background-image: linear-gradient(transparent, transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0.8em !important;
    /* margin: 5px !important; */
    cursor: pointer;
    transition: 0.8s;
}

.left-bar .sqrBtn:hover {
    opacity: 0.6;
}

.left-bar .active {
    width: auto;
    height: auto;
    font-size: 0.8em;
    font-size: 0.5em;
    background-image: linear-gradient(var(--yellow), var(--yellow));
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0.8em !important;
    /* margin: 5px !important; */
    cursor: pointer;
    transition: 0.8s;
}

.left-bar img {
    height: 3em;
    width: auto;
    margin-bottom: 5px;
}

.left-bar p {
    margin: 0;
    padding: 0;
    color: var(--gray);
    font-family: Roboto;
    font-size: 1em;
}

.left-bar .active p {
    color: #000000;
}
