.live-bg {
    background: linear-gradient(to right, #4158D0, #C850C0, #70E5FF);
    border-radius: 0.3em;
}

.live-img-btn {
    height: '60px';
    width: 'auto';
    background-color: '#3d3d3d';
}

.live-img-btn-active{
    height: '60px';
    width: 'auto';
    background-color: '#3d3d3d';
}

