.header {
    background-color: var(--yellow);
    height: 38px !important;
}

.header .logo {
    height: 30px;
    width: auto;
}

.headerBtn {
    border-radius: 0.3em;
    color: #ffffff;
    font-family: Advent Pro;
    font-weight: bold;
    font-size: 0.8em;
    padding: 0.2em 0.8em;
}

.signUpBtn {
    border: 2px solid var(--tomato);
    background-color: var(--tomato);
    margin: 0 0 0 0.5em;
}

.loginUpBtn {
    border: 2px solid #000000;
    background-color: rgba(0, 0, 0, 0.5);
}


.customAmountBtn {
    background-color: var(--dark);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 22px;
    width: auto;
}

.amountDepositBtn {
    border-radius: 50%;
    text-align: center;
    background-color: var(--gray);
    color: #FFFFFF;
    font-weight: bold;
}

.betNotificationNumber {
    position: relative;
}

.betNotificationNumber span {
    position: absolute;
    top: 2px;
    right: -2px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    text-align: center;
    color: var(--yellow);
    font-size: 8px;
    background-color: var(--tomato);
    border: 1px solid var(--yellow);
}

/* refresh button animation */

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        transform: rotate(1deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -ms-transform: rotate(1deg);
        -moz-transform: rotate(1deg);
        -webkit-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        transform: rotate(1deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.header-back-button {
    position: absolute;
    top: -11px;
    left: 5px;
}

.blink-image {
    animation: animate 1.5s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 1.0;
    }

    10% {
        opacity: 1.0;
    }

    20% {
        opacity: 0.7;
    }

    30% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.1;
    }

    60% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.3;
    }

    80% {
        opacity: 0.7;
    }

    90% {
        opacity: 1.0;
    }

    100% {
        opacity: 1.0;
    }
}

.header .dropdown-toggle::after {
    display: none;
}

.header .dropdown-menu {
    background-color: var(--light);
    /* position: absolute;
    inset: 0px 0px auto auto; */
    transform: translate3d(0px, 36px, 0px) !important;
    border: none;
    border-radius: 7px;
    box-shadow: -1px 1px 10px 0px rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 400px) {
    .header-back-button {
        left: 0;
    }
}