.marquee {
    margin: 0 auto;
    width: 100%;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    color: #000000;
}

.marquee:before,
.marquee:after {
    position: absolute;
    top: 0;
    width: 50px;
    height: 30px;
    content: "";
    z-index: 1;
}

.marquee:before {
    left: 0;
    /* background: linear-gradient(to right, #ccc 10%, transparent 80%); */
}

.marquee:after {
    right: 0;
    /* background: linear-gradient(to left, #ccc 10%, transparent 80%); */
}

.marquee__content {
    /* width: 600px; */
    display: flex;
    line-height: 30px;
    animation: marquee 20s linear infinite forwards;
    /* border: 1px solid blue ; */
}

.marquee__content:hover {
    animation-play-state: paused;
}

.list-inline {
    display: flex;
    justify-content: space-around;
    /* width: 33.33%; */
    width: 650px;
    /* border: 1px solid red; */
    /* reset list */
    list-style: none;
    padding: 0;
    margin: 0;

}

.list-inline li {
    /* border-right: 2px solid var(--gray); */
    padding: 0 5px;
}

@keyframes marquee {
    0% {
        transform: translateX(200%);
    }

    100% {
        transform: translateX(-250%);
    }
}