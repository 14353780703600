.nav-user-icon-border {
    border-right: 2px solid #2F2F2F;
}

.secondNavigation .nav-link {
    padding: 0;
}

.scroll-section {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

}

.secondNavigation ::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.secondNavigation a {
    /* color: #fff; */
    font-size: 13px;
}


.secondNavigation .bar .nav-link {
    padding: 3px 10px;
    border-right: 1px solid #494949;
}

.secondNavigation .bar .nav-link:hover {
    background-color: var(--yellow);
}

.secondNavigation .bar .active,
.secondNavigation .bar .active:hover {
    background-color: var(--yellow);
}

@media only screen and (max-width: 992px) { 
    .secondNavigation .bar .nav-link {
        padding: 3px 10px;
    }
}

@media only screen and (min-width: 426px) {
    .scroll-section {
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
    }
}