.apkDownload {
    display: flex;
    align-items: center;
    justify-content: center;
}

.apkDownload p {
    color: var(--gray);
    margin: 0;
    padding: 0;
}


.downloadApkBtn {
    border: none;
    background-color: var(--dark);
    /* padding: 5px 20px; */
    color: #ffffff;
}

.downloadApkBtn:disabled {
    border: none;
    background-color: var(--dark);
    /* padding: 5px 20px; */
    color: #ffffff;
    opacity: 0.5;
}

.downloadApkBtn .imgArea {
    background-color: var(--gray);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadApkBtn .imgArea img {
    height: 20px;
    width: auto;
}



/* gray */

/* footer links style */
.footer-area a:link .active,
.footer-area a:link .nav-link,
.footer-area a:link {
    text-decoration: none;
    color: #ADADAD !important;
}

.footer-area a:active,
.footer-area a:hover,
.footer-area a:visited {
    color: #ADADAD !important;
}