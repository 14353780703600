.profileImgModal .modal-dialog {
    margin-top: 40px !important;
    /* max-width: 40%; */
}

.profileImgModal .imgModalContainer {
    /* background: linear-gradient(90deg, #ac892f, #EACB7D, #ac892f); */
    background-image: linear-gradient(#C4C4C4, #C4C4C4);
    border-radius: 45%;
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileImgModal .modalImage {
    background-image: linear-gradient(#C4C4C4, #C4C4C4);
    border-radius: 45%;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}