.draggable-home-area {
    position: absolute;
    left: 0 !important;
    bottom: 0;
    z-index: 999;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.9s ease-in-out;
}

.react-draggable-dragging {
    opacity: 1 !important;
    transition: opacity 0.9s ease-in-out;
}

/* .react-draggable-dragged {
    left: 0 !important;
} */

@media only screen and (min-width: 769px) {
    .draggable-home-area {
        left: 33.35% !important;
        bottom: 0;
    }

    /* .react-draggable-dragged {
        left: 33.35% !important;
    } */
}