.userProfile .title-font {
    font-family: 'Inter';
    /* font-weight: 900; */
    font-size: 1em;
    text-transform: capitalize;
}

.active-user-image {
    border: 5px solid #20A052;
    border-radius: 50%;
}



.userImgBorder {
    /* background: linear-gradient(90deg, #ac892f, #EACB7D, #ac892f); */
    /* border-radius: 0.2em; */
    border-radius: 45%;
    height: auto;
    width: auto;
    position: relative;
    cursor: pointer;
}

.selectedUserImage {
    margin: 2px;
    background-image: linear-gradient(#D9D9D9, #D9D9D9);
    /* border-radius: 0.2em; */
    border-radius: 45%;
    opacity: 5;
    /* height: 120px;
    width: 120px; */
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.selectedUserImage img {
    width: 100%;
    height: auto;
}

.userImgBorder input[type=file] {
    position: absolute;
    opacity: -1;
    top: 0;
    left: 0;
}

.userImage {
    /* position: absolute; */
    margin: 2px;
    padding: 35px;
    /* background-image: linear-gradient(#D9D9D9, #D9D9D9); */
    background-image: linear-gradient(#C4C4C4, #C4C4C4);
    /* border-radius: 0.2em; */
    border-radius: 45%;
    opacity: 5;
    top: 0;
    left: 0;
}

.editImgBorder {
    background: linear-gradient(90deg, #ac892f, #EACB7D, #ac892f);
    /* border-radius: 0.2em; */
    border-radius: 45%;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .userImage img {
    width: 80px;
} */

.user-header {
    position: relative;
}

.agent-social-button {
    outline: none;
    border: 1px solid var(--gray);
    border-radius: 5px;
    height: 35px;
    width: 50px;
}