.custom-input {
    background-color: var(--light);
    /* background-color: red; */
    color: var(--gray);
    padding-top: 7px;
}

.custom-input .module {
    position: relative;
    background-color: inherit;
    color: inherit;
    border: 1.5px solid var(--yellow);
    border-radius: 5px;
}

.custom-input .module .input-group,
.custom-input .module .form-select,
.custom-input .module .form-control,
.custom-input .module .input-group-text {
    background-color: inherit !important;
    color: inherit;
    border-color: transparent !important;
    border-radius: inherit;
    /* outline: none !important; */
}

.module label {
    position: absolute;
    background-color: inherit;
    top: -16px;
    left: 10px;
    color: inherit;
    z-index: 100;
    padding: 0px 3px;
}


.custom-input .module input:focus::placeholder {
    color: transparent;
    opacity: 1;
    /* Firefox */
}

.custom-input .module small {
    position: absolute;
    padding: 0 4px;
    /* margin-left: 8px; */
    top: -14px;
    right: 8px;
    background-color: inherit;
    color: red;
    z-index: 10;
    font-size: 12px;
}

/* number type input field scroll off */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



